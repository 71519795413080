import React, { useEffect, useState } from 'react'

import './Property.scss'
import './PropertyOverview.scss'

import PropertyLayout from '../../components/Layout/PropertyLayout'
import PropertyBaseMenu from '../../components/PropertyBaseMenu/PropertyBaseMenu'
import PropertySlider from '../../components/PropertySlider/PropertySlider'
import { splitToChunks } from '../../components/Helpers/helpers'
import Drawer from '../../components/Drawer/Drawer'

import Fold from '../../assets/images/fold.png'
import Mesa from '../../assets/svg/mesa.svg'
import { getCorrectLogoBrand } from '../../utils/getCorrectLogoBrand'
import { DeviceState, useDevice } from '../../utils/checkScreen'

const PropertyFeatures = (data: any): JSX.Element => {
  const pageData = data
  const [device, setDevice] = useState({} as DeviceState)
  const { property } = pageData.pageContext
  const [drawer, showDrawer] = useState(false)
  const { brand } = property.details

  const logoBrand = getCorrectLogoBrand(brand)

  const Images = property.amenities ? [...property.amenities.images, ...property.amenities.videos] : []
  const Amenities = [...property.amenities.amenities]

  const closeDrawer = (): void => {
    showDrawer(false)
  }
  const openDrawer = (): void => {
    showDrawer(true)
  }

  useEffect(() => {
    setDevice(useDevice())
  }, [])

  return (
    <>
      <PropertyLayout active={4} pageData={pageData}>
        <PropertyBaseMenu pageData={pageData} />
        <div className="property-page-content">
          <div className="poperty-overview-page">
            <div
              className="property-overview-banner"
              id="project-overview-banner"
              style={{
                background: `linear-gradient(to right, rgba(26, 25, 25, 0.656), rgba(255, 255, 255, 0)),
              url('${property.amenities.images[0]}') center no-repeat`,
                backgroundSize: 'cover'
              }}
            >
              <div className="content-wrapper">
                <div className="brand-logo">
                  <img src={logoBrand} alt="brand-logo" />
                </div>
                <div className="property-logo fade-in">
                  <img src={property.details.imageLogo} alt="property logo" />
                </div>

                <div className="page-fold web-view-page-fold no-price features-page-fold">
                  <img src={Mesa} alt="page fold" className="page-shape" />
                  <img src={Fold} alt="edge fold" className="edge-fold" />
                  <div className="page-fold-content">
                    {device.isDesktop || device.isTablet ? <h1>{property.amenities.title}</h1> : null}
                    <small>{property.amenities.details}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="property-overview-content">
            <div className="mobile-property-context">
              <div className="property-logo-mobile">
                <img src={logoBrand} alt="brand-logo" className="x-brand-logo" />
                <img src={property.details.imageLogo} className="mobile-brand-logo" alt="property-brand-logo" />
              </div>
              <div className="type-title">
                {device.isMobile ? <h1>{property.amenities.title}</h1> : null}
                <p>{property.amenities.details}</p>
              </div>
            </div>
            <div className="property-text amenities-text">
              <h3>Amenities</h3>

              <div className="amenities-chunk">
                {splitToChunks(Amenities.slice(0, 4), 2).map((item: any, index: any) => {
                  return (
                    <div className="amenities-row" key={index}>
                      {item.map((amenity: any, i: any) => {
                        return (
                          <span key={i}>
                            <i className="la la-check-circle" /> {amenity.name}
                          </span>
                        )
                      })}
                    </div>
                  )
                })}
              </div>

              {Amenities.length > 4 && (
                <span className="show-amenities" onClick={openDrawer}>
                  Show all {Amenities.length} amenities
                </span>
              )}
            </div>

            <PropertySlider targetBanner="project-overview-banner" images={Images} />
          </div>
        </div>
        {drawer && (
          <Drawer onClose={closeDrawer}>
            <div className="amenities-drawer-list">
              <h3>Amenities</h3>

              {Amenities.map((item: any, index: number) => {
                return (
                  <div key={index} className="amenities-items">
                    <div className="item fade-in" style={{ animationDelay: `0.${index + 2}s` }}>
                      <i className="la la-check-circle" />
                      <div>
                        <span>{item.name}</span>
                        <small>{item.description}</small>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Drawer>
        )}
      </PropertyLayout>
    </>
  )
}

export default PropertyFeatures
